<template>
  <div class="articles">
    <nuxt-link v-for="item in items" :key="`article-${item._id}`" :to="`/articles/${item.pretty}`" class="item">
      <div class="poster">
        <img :data-src="item.poster" src="/static/no-poster.png" :alt="item.h1" class="lazyload">
      </div>
      <strong>{{ item.h1 }}</strong>
    </nuxt-link>
  </div>
</template>

<script>
export default {
  props: {
    items: { type: Array, require: true, default: () => ([])}
  },
}
</script>
